
/*
  default colors
*/
$background     : #fafbfc;
$white          : #ffffff;
$black          : #494949;
$main-red       : #a85d5d;

$grey-font      : #939292;
$grey-bold      : #939292;
$grey-sami      : #767575;
$grey-medium    : #9b9999;
$grey-regular   : #bcbcbc;
$grey-thin      : #f2f2f2;

$default        : #646363;
$default-light  : #fafbfc;
$primary        : #3598dc;
$dark           : $black;
$light          : $white;
$warning        : #dcbf72;
$info           : #51c1e2;
$success        : #006140;
$error          : #ac5052;
$secondary      : #2b3643;

$green            :  #006140;
$yellow           : #f4b125;

$default-btn-back    : #cecece;
$default-btn-text    : #828282;

$border         : #f2f2f2;

