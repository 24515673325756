@import "components/combine";
// font enable full html tag
* {font-family: $fontFamily;font-size: $space;box-sizing: border-box;}

/*
    * text-align
*/
$text-align : (
    center,
    left,
    right,
    justify,
);

@mixin text-align($keys : null)
{
    @each $value in $text-align
    {
        @if($keys == null)
        {
            .text-#{$value} {text-align: $value !important;}
        }
        @else
        {
            .text-#{$value}-#{$keys} {text-align: $value !important;}
        }
    }
}
// standart text align
@include text-align();
// media text align
@each $key, $value in $viewport-layouts
{
    @if($key == xs)
    {
        @include text-align($key);
    }
    @else
    {
        $min-width : map-get($value ,"min-width");
        @media screen and (min-width: $min-width) {
            @include text-align($key);
        }
    }
}
// end text-align


/*
    * row box properties
*/
.box {
    padding-left: $space;
    padding-right: $space;
}


/*
    * code - pre
*/
.code-box {
    background-color: $grey-thin;
    padding: $space-sm;

    .code-title {
        font-weight: bold;
    }
    pre {
        margin-top: $space;
        padding: $space;
        background:$white;
    }
    code {
        font-size: $size;
        line-height: $size-sm;
        color:$black;
        .tg {
            color:$grey-medium;
        }
    }
}

/*
    * Text tags styles
*/
// h tags
h6 {font-size: $space}
h5 {font-size: $space-sm}
h4 {font-size: $space-md}
h3 {font-size: $space-lg}
h2 {font-size: $space-xl}
h1 {font-size: $space-xxl}
h1,h2,h3,h4,h5,h6 {margin-bottom: $space;font-weight: bold;}

// p tags
p {
    margin-bottom: $space;
    font-size: $size;

    &.smaller {
        font-size: $size-smaller;
    }

    &.small {
        font-size: $size-small;
    }

    &.big {
        font-size: $size-big;
    }

    &.bigger {
        font-size: $size-biger;
    }

    &.large {
        font-size: $size-large;
    }
}

.bold {font-weight: bold;}

// pre, code  tagas
pre, pre code {padding: $zero;}
pre, pre code, pre tags {font-size: $size-big;}

// Quotes
blockquote {color: $default;@include border(0.2rem, solid, $grey-regular,left);padding: $space-xxs;line-height: ($space + 0.3rem);}
blockquote p {color: $default !important;}
blockquote small {margin-top: $space;display: $block;font-style: italic;}




// Line Height
.lh {line-height: ($space + 0.3rem);}
.lh-sm {line-height: ($space + 0.6rem);}
.lh-md {line-height: ($space + 0.9rem);}
.lh-lg {line-height: ($space + 1rem);}

/*
    * color
*/
// background
.default        {background-color: $default-btn-back !important;color:$default-btn-text !important;}
.default-light  {background-color: $default-light !important;color:$default !important;}
.primary        {background-color: $primary !important;color:$white !important;}
.dark           {background-color: $dark !important;color:$white !important;}
.light          {background-color: $light !important;color:$dark !important;}
.success        {background-color: $success !important;color:$white !important;}
.warning        {background-color: $warning !important;color:$white !important;}
.info           {background-color: $info !important;color:$white !important;}
.error          {background-color: $error !important;color:$white !important;}
.secondary      {background-color: $secondary !important;color:$white !important;}
.green            {background-color: $green !important;color:$white !important;}

// color
.default-text           {color:$default !important;}
.default-light-text     {color:$default-light !important;}
.primary-text           {color:$primary !important;}
.dark-text              {color:$dark !important;}
.light-text             {color:$light !important;}
.success-text           {color:$success !important;}
.warning-text           {color:$warning !important;}
.info-text              {color:$info !important;}
.error-text             {color:$error !important;}
.secondary-text         {color:$secondary !important;}
.green-text               {color:$green !important;}


/*
    * colmn-box
*/
.colmn {
    margin-bottom: $space;
    padding: $space;
    background-color: $white;

    &.not-bg {
        background-color: none;
    }

    &.bordered {
        @include border(1px, solid, $border);
    }

    &.basic {
        background: none;
    }

    &.shadow {
        @include shadow(0px, 1px, 10px,  $grey-thin);
    }

    &.first {
        margin-bottom: $zero;
        margin-top: $space;
    }

    &.last {
        margin-bottom: $zero;
    }

    p {
        margin-bottom: $zero;
        color:$default;
    }

    .colmn-title {
        margin-top: $space-xxs;
        @include border(.07rem, solid, $border, bottom);
        h5 {
            font-size: 1.2rem;
            font-weight: 400;
            color:$green;
            @include border(.2rem, solid, $green, bottom);
            margin-bottom: -.15rem;
            display: inline-block;
            padding-right: $space-lg;
            padding-bottom: $space-xs;
            font-weight: bold;
        }
    }
}

/*
    * Tab
*/
.tab-main {
    width: $full;
    margin-bottom: $space;

    .tab-head {

        ul {
            @include border(.05rem, solid, $border, bottom);

            li{
                display: inline-block;
                padding: $space;
                margin-bottom: -.2rem;
                cursor: pointer;
                color:$default;

                &.active {
                    color:lighten($success, 10%);
                    @include border(.3rem, solid, lighten($success, 10%), bottom);
                }
            }
        }
    }

    .tab-body {

        .tab-content {
            padding: $space;
            display: none;

            &.active{
                display: block;
            }
        }
    }
}

/*
    * DropDown
*/

.dropdown {
    position: relative;
    display: inline-block;

    .dd-active {

        &.dropdown-active {
            background-color:$error;
        }

        .button {
        }
    }
    .dd-show {
        display: none;
    }
    &.dd-active {

         .dd-show {
            display: block;

            &.desc {
                width: 15rem;
                margin-top: $space-xxs;
                padding: $space;
            }
        }
    }

}

/*
    * padding remove space
*/
.pad-0 {
    padding: 0;
}
.pad-0-left {
    padding-left: 0;
}
.pad-0-right {
    padding-right: 0;
}

.img-responsive {
    width: $full;
    min-height: $full;
}

.wid-f {
    width: $full;
}

/*
    * direction
*/
.fl-left {
    float: left;
}
.fl-right {
    float: right;
}
.fl-none {
    float: none !important;
}
.inline {
    display: inline-block;
}
.block {
    display: block;
}
.none {
    display: none !important;
}
.relative {
    position: relative;
}

/*
    * view
*/
@each $key, $value in $viewport-layouts {
    @include responsive($key){
        .none-#{$key} {
            display: none !important;
        }
        .block-#{$key} {
            display: block !important;
        }
    }
}

/* lists */
ul.lists {
    li {
        &:last-child {
            a {
                border-bottom:0;
            }
        }
        a {
            display: block;
            padding: .3rem;
            color:$default;

            &:hover {
                color: $grey-regular;
            }
        }
    }

    &.lists-icon {
        li {
            a {
                &:before {
                    content: "\f105";
                    font: normal normal normal $space/1 FontAwesome;
                    color:$default;
                    margin-right: $space-xs;
                }
            }
        }
    }
}

/* breadcrump */
.breadcrump {
    display: inline-block;
    width: $full;
    li {
        display: inline-block;
        margin-right: $space-xxs;
        a {
            font-size: $size;
            color:$green;

            &:after {
                content: "\f105";
                font: normal normal normal $space/1 FontAwesome;
                margin-left: $space-xxs;
            }
        }
        span {
            color:$default;
        }
    }
}


/*
    * spaca class 
*/
// bottom space
.btm-spc-xxs {
    margin-bottom: $space-xxs;
}
.btm-spc-xs {
    margin-bottom: $space-xs;
}
.btm-spc {
    margin-bottom: $space;
}
.btm-spc-sm {
    margin-bottom: $space-sm;
}
.btm-spc-md {
    margin-bottom: $space-md;
}
.btm-spc-lg {
    margin-bottom: $space-lg;
}
.btm-spc-xl {
    margin-bottom: $space-xl;
}
.btm-spc-xxl {
    margin-bottom: $space-xxl;
}
// top Space

.top-spc-xxs {
    margin-top: $space-xxs;
}
.top-spc-xs {
    margin-top: $space-xs;
}
.top-spc {
    margin-top: $space;
}
.top-spc-sm {
    margin-top: $space-sm;
}
.top-spc-md {
    margin-top: $space-md;
}
.top-spc-lg {
    margin-top: $space-lg;
}
.top-spc-xl {
    margin-top: $space-xl;
}
.top-spc-xxl {
    margin-top: $space-xxl;
}

