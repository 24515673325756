// responsive

@mixin responsive($media, $min : false){

    // xs = phone
    @if($media == xs) {
        @if($min == true){
            @media (min-width: 0) {
                @content;
            }
        }@else{
            @media screen and (max-width: $media-small) {
                @content;
            }
        }
    }

    // sm = tablet and  big phone
    @elseif($media == sm) {
        @if($min == true){
            @media (min-width: $media-small) {
                @content;
            }
        }@else{
            @media (min-width: $media-small) and (max-width: $media-medium){
                @content;
            }
        }
    }

    // md = small desktop
    @elseif($media == md) {
        @if($min == true){
            @media (min-width: $media-medium) {
                @content;
            }
        }@else{
            @media (min-width: $media-medium) and (max-width: $media-large) {
                @content;
            }
        }
    }

    // lg = normal desktop
    @elseif($media == lg) {
        @if($min == true){
            @media (min-width: $media-large) {
                @content;
            }
        }@else{
            @media (min-width: $media-large) {
                @content;
            }
        }
    }

}
