@font-face {
	font-family: 'Geogrotesque Md It';
	src: url('../fonts/subset-Geogrotesque-MediumItalic.eot');
	src: url('../fonts/subset-Geogrotesque-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-MediumItalic.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-MediumItalic.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-MediumItalic.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-MediumItalic.svg#Geogrotesque-MediumItalic') format('svg');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Geogrotesque Md';
	src: url('../fonts/subset-Geogrotesque-Medium.eot');
	src: url('../fonts/subset-Geogrotesque-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-Medium.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-Medium.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-Medium.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-Medium.svg#Geogrotesque-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Geogrotesque UlLg';
	src: url('../fonts/subset-Geogrotesque-UltraLight.eot');
	src: url('../fonts/subset-Geogrotesque-UltraLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-UltraLight.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-UltraLight.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-UltraLight.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-UltraLight.svg#Geogrotesque-UltraLight') format('svg');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Geogrotesque Rg It';
	src: url('../fonts/subset-Geogrotesque-RegularItalic.eot');
	src: url('../fonts/subset-Geogrotesque-RegularItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-RegularItalic.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-RegularItalic.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-RegularItalic.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-RegularItalic.svg#Geogrotesque-RegularItalic') format('svg');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Geogrotesque Th It';
	src: url('../fonts/subset-Geogrotesque-ThinItalic.eot');
	src: url('../fonts/subset-Geogrotesque-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-ThinItalic.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-ThinItalic.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-ThinItalic.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-ThinItalic.svg#Geogrotesque-ThinItalic') format('svg');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Geogrotesque Lg It';
	src: url('../fonts/subset-Geogrotesque-LightItalic.eot');
	src: url('../fonts/subset-Geogrotesque-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-LightItalic.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-LightItalic.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-LightItalic.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-LightItalic.svg#Geogrotesque-LightItalic') format('svg');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Geogrotesque Bd';
	src: url('../fonts/subset-Geogrotesque-Bold.eot');
	src: url('../fonts/subset-Geogrotesque-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-Bold.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-Bold.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-Bold.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-Bold.svg#Geogrotesque-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Geogrotesque SmBd It';
	src: url('../fonts/subset-Geogrotesque-SemiBoldItalic.eot');
	src: url('../fonts/subset-Geogrotesque-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-SemiBoldItalic.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-SemiBoldItalic.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-SemiBoldItalic.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-SemiBoldItalic.svg#Geogrotesque-SemiBoldItalic') format('svg');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Geogrotesque Bd It';
	src: url('../fonts/subset-Geogrotesque-BoldItalic.eot');
	src: url('../fonts/subset-Geogrotesque-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-BoldItalic.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-BoldItalic.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-BoldItalic.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-BoldItalic.svg#Geogrotesque-BoldItalic') format('svg');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Geogrotesque UlLg It';
	src: url('../fonts/subset-Geogrotesque-UltraLightItalic.eot');
	src: url('../fonts/subset-Geogrotesque-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-UltraLightItalic.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-UltraLightItalic.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-UltraLightItalic.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-UltraLightItalic.svg#Geogrotesque-UltraLightItalic') format('svg');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Geogrotesque Rg';
	src: url('../fonts/subset-Geogrotesque-Regular.eot');
	src: url('../fonts/subset-Geogrotesque-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-Regular.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-Regular.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-Regular.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-Regular.svg#Geogrotesque-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Geogrotesque SmBd';
	src: url('../fonts/subset-Geogrotesque-SemiBold.eot');
	src: url('../fonts/subset-Geogrotesque-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-SemiBold.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-SemiBold.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-SemiBold.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-SemiBold.svg#Geogrotesque-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Geogrotesque Lg';
	src: url('../fonts/subset-Geogrotesque-Light.eot');
	src: url('../fonts/subset-Geogrotesque-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-Light.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-Light.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-Light.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-Light.svg#Geogrotesque-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Geogrotesque Th';
	src: url('../fonts/subset-Geogrotesque-Thin.eot');
	src: url('../fonts/subset-Geogrotesque-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/subset-Geogrotesque-Thin.woff2') format('woff2'),
		url('../fonts/subset-Geogrotesque-Thin.woff') format('woff'),
		url('../fonts/subset-Geogrotesque-Thin.ttf') format('truetype'),
		url('../fonts/subset-Geogrotesque-Thin.svg#Geogrotesque-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
}
