/*
    * Table Standart
*/
table {
	border-collapse: collapse;
	border-spacing: 0;
	max-width: $full;
	width: $full;
	empty-cells: show;
	font-size: $size;
	line-height: $size-sm;
}
table caption {
    text-align: left;
    font-size: $size;
    font-weight: $weight;
    color: $default;
}

th {
	text-align: $left;
	font-weight: $weight_regular;
	vertical-align: $down;
}
td {
	vertical-align: $up;
}
tr.align-middle td,
td.align-middle {
    vertical-align: $middle;
}
th,
td {
	padding: $space;
	@include border(0.1rem, solid, $border, bottom);
	&:first-child {
		padding-left: $zero;
	}
	&:last-child {
		padding-right: $zero;
	}
}
tfoot th,
tfoot td {
	color: $grey-regular;
}
/*
    * Bordered
*/
table.bordered {

	& td,
	& th {
		@include border(1px, solid, $border);
	}

}

/*
    * Striped
*/
table.striped tr:nth-child(odd) td {
	background: $default-light;
}

table.bordered,
table.striped {
	& td,
	& th {
		&:first-child {
			padding-left: $space;
		}
		&:last-child {
			padding-right: $space;
		}
	}
}

/*
    * Non Style
*/
table.nonstyled {
	& td,
	& th {
		border: $none;
		padding: $zero;
	}
}