// Fieldset
fieldset {
    @include border(0.1rem, solid, $border);
    padding: $space-lg;
    margin: $space-lg $zero;
}
legend {
	font-weight: $weight_bold;
	font-size: $size-small;
	text-transform: uppercase;
	padding: $zero $space;
	margin-left: -$space;
	top: 0.2rem;
	position: relative;
	line-height: $zero;
}
input,
textarea,
select,
input.frm,
textarea.frm,
select.frm {
    display: $block;
    width: $full;
	font-size: $size;
	min-height: $space-lg;
    max-width: $full;
    outline: $none;
    vertical-align: $middle;
	background-color: $white;
    color:$dark;
    padding: .6rem .9rem;
    @include border(.05rem, solid, darken($border, 5%));
    @include shadow(0, 0.1rem, 0.5rem, lighten($default-light, 80%));
    @include easing();
}
// Sizing
input.small,
textarea.small,
select.small {
    height: $space-sm;
    font-size: $size-small;
    padding: $zero $space-xxs;
}
input.big,
textarea.big,
select.big {
    height: $space-xxl;
    font-size: $size-lg;
    padding: 0 $space-lg;
}
// States
input,
textarea,
select {
    &:focus {
    	outline: $none;
    	background-color: lighten($default-light, 20%);
    	border-color: darken($border, 15%);
    }
    &.error {
	    background-color: lighten($error, 20%);
    	border-color: lighten($error, 10%);
    	&:focus {
            border-color: lighten($error, 15%);
    	}
	}
    &.success  {
    	background-color: lighten($success, 20%);
    	border-color: lighten($success, 10%);
    	&:focus {
            border-color: lighten($success, 15%);
    	}
    }
    &:disabled,
    &.disabled {
    	resize: none;
    	opacity: $opacity-disabled;
    	cursor: default;
    	font-style: italic;
    	color: $default;
	}
}
select,
select.frm {
    -webkit-appearance: $none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 129 129"><path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z" fill="#8c8c8c"/></svg>');
    background-repeat: no-repeat;
    background-position: $right 1rem $center;
    background-size: .8rem;
    @include borderRadius(0);
}
select[multiple],
select[multiple].frm {
    background-image: $none;
    height: auto;
    padding: $space-xxs $space-xs;
}
textarea,
textarea.frm {
    height: auto;
    min-height: 7.5rem;
    padding: $space;
    line-height: $space-sm;
    vertical-align: $up;
}
input[type="file"],
input[type="file"].frm {
    width: auto;
    border: $none;
    padding: $zero;
    height: auto;
    background: $none;
    box-shadow: $none;
    display: $line-block;
}
input[type="search"],
input.search,
input[type="search"].frm,
input.search.frm {
    background-repeat: no-repeat;
    background-position: $space-xs $right;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 451 451"><path d="M447.05,428l-109.6-109.6c29.4-33.8,47.2-77.9,47.2-126.1C384.65,86.2,298.35,0,192.35,0C86.25,0,0.05,86.3,0.05,192.3   s86.3,192.3,192.3,192.3c48.2,0,92.3-17.8,126.1-47.2L428.05,447c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4   C452.25,441.8,452.25,433.2,447.05,428z M26.95,192.3c0-91.2,74.2-165.3,165.3-165.3c91.2,0,165.3,74.2,165.3,165.3   s-74.1,165.4-165.3,165.4C101.15,357.7,26.95,283.5,26.95,192.3z" fill="#8c8c8c"/></svg>');
    padding-right: $space-lg;
}
input[type="radio"],
input[type="checkbox"]
input[type="radio"].frm,
input[type="checkbox"].frm {
    display: $line-block;
    width: auto;
    height: auto;
    padding: $zero;
}
.form-item label {
    display: $block;
    color: $default;
    margin-bottom: .4rem;
    font-size: $size-big;

    &.basic {
        margin-bottom: 0;
    }

    &.checkbox,
    & .desc,
    & .success,
    & .error {
    	text-transform: none;
    	font-weight: normal;
    }
    &.checkbox {
        font-size: $size;
        line-height: $size-md;
    	cursor: pointer;

        & input {
            margin-top: $zero;
        }
    }

}


.frm-checkboxes {
    & label.checkbox {
        display: $line-block;
        margin-right: $space-sm;
	}
}
.err {
    position: relative;
    margin-top: .3rem;
	color: $error;
	font-size: $size-small;
    display: $line-block;
}
.desc {
    color: $default;
    font-size: $size-smaller;
    line-height: $space;
    display: $line-block;
}
span.desc {
    margin-top: .2rem;
    display: $line-block;
}
div.desc {
    margin-top: $space-xxs;
    margin-bottom: -$space-xs;
}
.form-buttons {
    & button,
    & .button {
        margin-right: $space-xs;
    }
}
.form-item {
    margin-bottom: $space;

    &.border {
        position: relative;
        label {
            position: absolute;
            top:$space;
            left: $space-xxs;
            z-index: 3;
            @include easing(.5);
        }
        .frm {
            position: relative;
            z-index:4;
            padding: $space $space-xs $space $space-xs;
            border-top:0;
            border-left:0;
            border-right:0;
            border-width: .1rem;
            background-color: transparent;
            
            &:focus ~ label, 
            &:valid ~ label {
                top: -$space-xxs;
                font-size: $size-xs;  
                font-weight: bold;
                color:$dark;
            }
        }

    }
}
.form {
    & > .frm-item:last-child {
        margin-bottom: $zero;
    }
    & .row:last-child {
        & .frm-item {
            margin-bottom: $zero;
        }
    }
    & span.success,
    & span.error {
        font-size: $size-smaller;
        line-height: $space-xs;
        margin-left: $space-xxs;
    }
}
.form-inline {
    display: inline-block;
    width: auto;
    margin-right: $space-xs;

    &.form-item:last-child {
        margin-right: 0;
    }

    & input,
    & textarea,
    & select {
        display: inline-block;
        width: auto;
    }
}

.form-item {
    &.even {
        .checkbox,
        .radio {
            margin-top: $space-lg;
        }
    }
    &.basic {
        margin-bottom: 0;
    }
    .checkbox,
    .radio {

        &.small {
            font-size: .8;

            span {
                padding: .2rem;
                margin-right: .2rem;
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        display: $line-block;
        font-size: $size;
        cursor: pointer;

        input {
            display: none;

            &:checked + span i {
                transform: scale(1) rotate(0deg);
                opacity: $opacity;
            }
        }

        span {
            padding: .3rem;
            margin-right: .3rem;
            width: $size-md;
            height: $size-md;
            display: inline-block;
            @include border(.05rem, solid, darken($border, 15%));
            line-height: 1;

            i {
                -webkit-transform: scale(3) rotateZ(20deg);
                opacity: 0;
                line-height: 0.88rem;
                @include easing();
            }
        }
    }
}

.frm {
    &.constant {
        height: $input-constant;
    }
    &.constant-medium {
        height: $input-constant-medium;
    }
    &.constant-big {
        height: $input-constant-big;
    }
    &.constant-bigger {
        height: $input-constant-bigger;
    }
    &.constant-bigger {
        height: $input-constant-large;
    }
}

.form-item .radio {

    span {
        @include borderRadius(50%);
    }
}

.form-item .form-item:last-child,
.form-item .form-item.form-inline {
    margin-bottom: 0;
}
