.alert {
  width: $full;
  padding: $space;
  font-size: .9rem;
  font-weight: 400;
  @include border(.05rem, solid, $border);
  background-color: $default-light;
  color:$grey-regular;
  @include borderRadius();


  &.alert-info {
    background-color: lighten($info, 30%);
    border-color:lighten($info, 25%);
    color:darken($info, 35%);
  }

  &.alert-warning {
    background-color: lighten($warning, 30%);
    border-color:lighten($warning, 25%);
    color:darken($warning, 35%);
  }

  &.alert-error {
    background-color: lighten($error, 30%);
    border-color:lighten($error, 25%);
    color:darken($error, 35%);
  }

  &.alert-success {
    background-color: lighten($success, 30%);
    border-color:lighten($success, 25%);
    color:darken($success, 30%);
  }

}