@font-face {
  font-family: 'icomoon';
  src:  url('../../dist/fonts/icomoon.eot?3jt2ur');
  src:  url('../../dist/fonts/icomoon.eot?3jt2ur#iefix') format('embedded-opentype'),
    url('../../dist/fonts/icomoon.ttf?3jt2ur') format('truetype'),
    url('../../dist/fonts/icomoon.woff?3jt2ur') format('woff'),
    url('../../dist/fonts/icomoon.svg?3jt2ur#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}


.ic {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.ic-basket:before {
    content: "\e900";
  }
  &.ic-fast-securty:before {
    content: "\e901";
  }
  &.ic-system:before {
    content: "\e902";
  }
  &.ic-shoping:before {
    content: "\e903";
  }

  &.ic-credit-cart:before {
    content: "\e906";
  }
  &.ic-transfer:before {
    content: "\e907";
  }

  &.ic-take-now:before {
    content: "\e908";
  }

  &.ic-visa {

    .path1:before {
      content: "\e902";
      color: rgb(249, 165, 51);
    }
    .path2:before {
      content: "\e904";
      margin-left: -3.095703125em;
      color: rgb(0, 85, 85);
    }
    .path3:before {
      content: "\e905";
      margin-left: -4.095703em;
      color: rgb(0, 102, 178);
    }

  }
}
