
// fonts
$fontFamily     : Geogrotesque Rg !default;
$fontSans       : '';

$size-xxs       : 0.5rem;
$size-xs        : 0.7rem;
$size           : 1rem;
$size-sm        : 1.3rem;
$size-md        : 1.6rem;
$size-lg        : 2rem;

$size-smaller   : 0.7rem;
$size-small     : 0.9rem;
$size-big       : 1.1rem;
$size-biger     : 1.3rem;
$size-large     : 1.5rem;

// normal space
$space-xxs  : 0.5rem;
$space-xs   : 0.7rem;
$space      : 1rem;
$space-sm   : 1.5rem;
$space-md   : 1.8rem;
$space-lg   : 2rem;
$space-xl   : 2.5rem;
$space-xxl  : 3rem;
$space-xxxl : 3.5rem;
$zero       : 0;
$full       : 100%;

// font weight
$weight_thin     : 200;
$weight_light    : 300;
$weight          : 500;
$weight_regular  : 700;
$weight_medium   : 800;
$weight_bold     : bold;
$weight_bolder   : bolder;

// display
$block          : block;
$none           : none;
$line-block     : inline-block;

// direction
$left   : left;
$center : center;
$right  : right;
$jutify : justify;
$down   : bottom;
$up     : top;
$middle : middle;

// color names
$color-names : (
    "default",
    "default-light",
    "primary",
    "light",
    "dark",
    "warning",
    "info",
    "success",
    "error",
    "secondary",
    "b2c",
    "b2b",
    "c2c",
    );


// forms
$input-height       : 3rem;
$input-height-small : 2rem;
$input-height-big   : 4rem;

//checkbox
$check-rotate       : 20deg;
$check-scale        : 3;


// opacity
$opacity            : 1;
$opacity-disabled   : .6;

// responsive media
$media-small         : 45rem;
$media-medium        : 68rem;
$media-large         : 80rem;


// constant input
$input-constant          : 2.6rem;
$input-constant-medium   : 2.8rem;
$input-constant-big      : 3.1rem;
$input-constant-bigger   : 3.1rem;
$input-constant-large    : 3.6rem;


// constant button
$btn-constant          : 2.6rem;
$btn-constant-medium   : 2.8rem;
$btn-constant-big      : 3.1rem;
$btn-constant-bigger   : 3.1rem;
$btn-constant-large    : 3.6rem;
$btn-constant-xlarge    : 4.3rem;

// wide button
$btn-wide          : 2.6rem;
$btn-wide-medium   : 2.8rem;
$btn-wide-big      : 3.1rem;
$btn-wide-bigger   : 3.1rem;
$btn-wide-large    : 3.6rem;

// basket conntent width

$z-index-overlay : 9999;
