/* function */


/*
    borders function
*/
@mixin border($width, $type, $color, $direction:null)
{
    @if(color != "" or $width != "" or $type != "")
    {
        @if($direction == null)
        {
            border: $width  $type $color;
        }
        @else
        {
            border-#{$direction} : $width $type $color;
        }
    }
}


/*
    border-radius funtion
*/
@mixin borderRadius($key : 0.2rem){
    -webkit-border-radius: $key;
    -moz-border-radius: $key;
    -ms-border-radius: $key;
    border-radius: $key;
}


/*
    shadow
*/
@mixin shadow($top, $left, $blur, $color, $type:box, $inset:false)
{
    @if($top != "" and $left != "" and $blur != "")
    {
        @if($inset)
        {
            #{$type}-shadow:$top $left $blur $color inset;
        }
        @else
        {
             #{$type}-shadow:$top $left $blur $color;
        }
    }
}


/*
    * Button
*/

@mixin button($background, $text, $border : null)
{
	color: $text;
	background-color: $background;

    @if($border != null){
        @include border(0.1rem, solid, lighten($background, 10%));
    }

    a {
        color: $text;
    }

	&:hover {
	    color: $text;
	    background-color: lighten($background, 20%);
        @if($border != null){
            @include border(0.1rem, solid, lighten($background, 20%));
        }
	}

    &.not-hover {
        background-color: $background;
        color: $text;
    }

    &:disabled,
    &.disabled {
	    color: lighten($background, 30%);
	    background-color: lighten($background, 60%);
        @if($border != null){
            border-color: lighten($background, 60%);
        }
        cursor: no-drop;
    }
    &.outline {
        background: none;
        color: $background;
        border-color: $background;

        &:hover {
            color: rgba($background, .6);
            border-color: rgba($background, .5);
        }
        &:disabled,
        &.disabled {
            background: none;
    	    color:lighten($background, 25%);
    	    border-color: lighten($background, 25%);
        }
        &.active{
            color:darken($text, 50%);
            border-color: lighten($background, 15%);
        }
    }
    &.border {
        @include border(0.1rem, solid, lighten($background, 20%));
    }
    &.active {
        background: lighten($background, 15%);
        @if($border != null){
            border-color: darken($background, 10%);
        }
    }

    &.arrow-active {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            border-width: .8rem;
            border-style: solid;
            border-color: lighten($background, 15%) transparent transparent transparent;
            bottom:-1.6rem;
            left: 0;
            right: 0;
            width: 0;
            height: 0;
            margin: auto;
            display: none;
        }

        &.active {
            &:after {
                display: block;
            }
        }
    }

    &.constant {
        height: $btn-constant;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    &.constant-medium {
        height: $btn-constant-medium;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    &.constant-big {
        height: $btn-constant-big;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    &.constant-bigger {
        height: $btn-constant-bigger;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    &.constant-large {
        height: $btn-constant-large;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    &.constant-xlarge {
        height: $btn-constant-xlarge;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    
    &.wide {
        padding-left: $btn-wide;
        padding-right: $btn-wide;
    }
    &.wide-medium {
        padding-left: $btn-wide-medium;
        padding-right: $btn-wide-medium;
    }
    &.wide-big {
        padding-left: $btn-wide-big;
        padding-right: $btn-wide-big;
    }
    &.wide-bigger {
        padding-left: $btn-wide-bigger;
        padding-right: $btn-wide-bigger;
    }
    &.wide-bigger {
        padding-left: $btn-wide-large;
        padding-right: $btn-wide-large;
    }

}


// easing
@mixin easing($eas : 0.3, $trs : ease)
{
    -webkit-transition: all $eas+s ease;
    -moz-transition: all $eas+s ease;
    -ms-transition: all $eas+s ease;
    transition: all $eas+s ease;
}
