button,
.btn {
	font-size: $size;
	color: $default-btn-text;
	background-color: $default-btn-back;
	min-height: $space;
	padding: $space-xs $space;
	cursor: pointer;
	display: $line-block;
	line-height: $space;
    font-weight: $weight;
    width: auto;
	@include border(.05rem, solid, transparent);
    @include easing();
	vertical-align: $middle;
    text-align: center;
    outline: none !important;


	i {
        position: relative;
        top: -.05rem;

        &.left {
            margin-left: 0rem;
            margin-right: .7rem;
        }
        &.right {
            margin-right: 0rem;
            margin-left: .7rem;
        }
	}

}
button:hover,
.btn:hover {
    outline: $none;
	text-decoration: $none;
	color: lighten($default, 10%);
	background-color: lighten($default-btn-back, 10%);
}

// Disabled
.btn:disabled,
.btn.disabled {
	cursor: default;
	font-style: normal;
	color: lighten($default, 40%);
	background-color: lighten($default, 70%);
}

// Size
.btn.small {
    font-size: $size-xs;
    min-height: $space-xs;
    padding: $space-xxs $space-xs;
    line-height: 1.1;
}
.btn.big {
    font-size: $size-md;
    min-height: $space-md;
    padding: $space-md;
}
.btn.large {
    font-size: $size-lg;
    min-height: $space-lg;
    padding: $space-lg;
}

.btn-full {
    width: $full;
}


// Round
.btn.raund {
    @include borderRadius();
}

// Round Full
.btn.radius {
    @include borderRadius(5rem);
}

.btn.circle {
  @include borderRadius(50%);
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn.circle-small {
  @include borderRadius(50%);
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.circle-medium {
  @include borderRadius(50%);
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.circle-big {
  @include borderRadius(50%);
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.circle-bigger {
  @include borderRadius(50%);
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.circle-large {
  @include borderRadius(50%);
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}



// Shadow
.btn.shadow {
    @include shadow(0, 0.1rem, 0.3rem, $default-light);
}

// inline
.btn.btn-inline {
    margin-left: -.12rem;
    margin-right: -.12rem;
}

@each $key, $value in $color-names
{
    button.btn-#{$key},
    .btn.btn-#{$key}
    {
        @if($key == default-light){ @include button($default-light, $default-btn-text,$default-btn-back);}
        @elseif($key == default){ @include button($grey-regular, $white);}
        @elseif($key == primary){ @include button($primary, $white);}
        @elseif($key == light) { @include button($white, $dark);}
        @elseif($key == dark){ @include button($dark, $white);}
        @elseif($key == warning){ @include button($warning, $white);}
        @elseif($key == info){ @include button($info, $white);}
        @elseif($key == success){ @include button($success, $white);}
        @elseif($key == error){ @include button($error, $white);}
        @elseif($key == secondary){ @include button($secondary, $white);}
    }
}
