/* deniz and islam project */

body {
    background-color:$white;
}

// ICONS
.icon {
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.btn.btn-success {
    &:hover {
        background-color: #000;
    }
}


header {
    position: fixed;
    top:0;
    left: 0;
    width: $full;
    z-index: 9999;
    @include responsive(sm){position: relative;}
    @include responsive(xs){position: relative;}

    &.scroll {
        .main-header {
            background-color: $white;
            @include shadow(0, .2rem, .5rem, $grey-regular);
        }
    }

    &.static {
        .main-header {
            background-color: $white;
        }
    }

    .top-header {
        height: 1.5rem;
        background-color: #1a312a;
        @include responsive(sm){display: none !important;}
        @include responsive(xs){display: none !important;}
    }
    // main header
    .main-header {
        background-color: rgba(255, 255, 255, 0.65);
        @include responsive(sm){background-color: $white;}
        @include responsive(xs){background-color: $white;}

        .logo {
            padding: $space-xs $space-sm;

            img {
                width: 80%;
            }
        }
        .menu {
            .responsive-button {
                display: none;
                margin-top: -3.3rem;
                margin-bottom: 1.5rem;
                padding-right: 0.5rem;

                @include responsive(sm) {display: block;}
                @include responsive(xs) {display: block;}
            }
            ul {
                @include responsive(sm){display: none;}
                @include responsive(xs){display: none;}
                li {
                    display: inline-block;
                    position: relative;
                    @include responsive(sm){display: block;}
                    @include responsive(xs){display: block;}

                    &.active,
                    &:hover {
                        a {
                            border-color: $green;
                            @include responsive(sm){background-color: $white;}
                            @include responsive(xs){background-color: $white;}
                            span {
                                color: $green;
                            }
                        }
                    }

                    &:hover {
                        ul {
                            visibility: visible;
                            opacity: 1;
                            @include responsive(sm){display: block;}
                            @include responsive(xs){display: block;}
                        }
                    }

                    a {
                        font-family: 'Geogrotesque Md';
                        display: block;
                        padding: $space-sm .9rem;
                        text-align: left;
                        position: relative;
                        @include border(.15rem, solid, transparent, bottom);
                        @include easing();
                        @include responsive(md) {padding: 1.5rem 0.6em;}
                        @include responsive(sm) {width: $full;background-color: $green;color:$white;}
                        @include responsive(xs) {width: $full;background-color: $green;color:$white;}

                        span {
                            display: block;
                            color: $dark;
                            font-size: 1.1rem;
                            font-weight: bold;
                            @include responsive(sm) {color:$white;}
                            @include responsive(xs) {color:$white;}
                        }
                        em {
                            display: block;
                            font-size: .8rem;
                            color: $dark;
                            margin-top: .4rem;
                            font-style: normal;
                            @include responsive(sm) {display: none;}
                            @include responsive(xs) {display: none;}
                        }
                    }

                    ul {
                        position: absolute;
                        width: 13rem;
                        background-color: $green;
                        visibility: hidden;
                        opacity: 0;
                        @include easing();
                        @include responsive(sm){position: relative;display: none;background-color: $green;width: $full;}
                        @include responsive(xs){position: relative;display: none;background-color: $green;width: $full;}

                        li {
                            display: block;
                            @include responsive(sm){background-color: $green;}
                            @include responsive(xs){background-color: $green;}
                            a {
                                color: $white;
                                display: block;
                                padding: $space;
                                @include responsive(sm){background-color: lighten($green, 10%) !important;}
                                @include responsive(xs){background-color: lighten($green, 10%) !important;}

                                &:hover {
                                    background-color: #000000;
                                    @include responsive(sm){background-color: #000000 !important;}
                                    @include responsive(xs){background-color: #000000 !important;}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// container
section {

    // slider
    .slider {
        max-height: 41rem;
        position: relative;
        overflow: hidden;
        .owl-carousel {
            position: static !important;
        }
        .owl-item {
            .item {
                width: $full;
                min-height: $full;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    //width: auto;
                    width: $full;
                }
            }
            &.active {
                .slider-text{
                    h1{
                        display: block;
                    }
                    .btn {
                        display: inline-flex;
                    }
                }
            }
        }
        .slider-text {
            position: absolute;
            width: $full;
            height: $full;
            top: 0;
            left:0;
            padding-top: $space-xl * 5;
            @include responsive(sm){padding-top: $space-xl * 3; text-align: center;}
            @include responsive(xs){padding-top: 3.5rem; text-align: center;}

            h1 {
                display: none;
                font-style: italic;
                font-size: 4rem;
                color:$white;
                @include shadow(0, .2rem, .5rem, #a9a6a6, text);
                @include responsive(xs){font-size: 1.2rem;}
            }

            .btn {
                display: none;
                margin-top: $space-lg;
            }
        }

        .owl-controls {
            position: absolute;
            top: 87%;
            width: 100%;
            right: 0;
            text-align: right;
            padding-right: 4%;
            @include responsive(sm) {display: none;}
            @include responsive(xs) {display: none;}

            .owl-dots {
                .owl-dot {
                    height: 1rem;
                    width: 1rem;
                    @include border(.1rem, solid, #000000);
                    display: inline-block;
                    margin: 0.2rem;
                    border-radius: 50%;

                    &.active {
                        background-color: $green;
                        border-color:$green;
                    }
                }
            }
        }
    }

    // search box
    .search-box {
        position: relative;
        margin-top: -$space-xl;
        z-index: 9;
        @include responsive(sm){margin-top: $space-xl;}
        @include responsive(xs){margin-top: $space-xl;}

        .box {
            padding: 0;
            background-color: $white;
            @include shadow(0, .2rem, .5rem, lighten($grey-regular, 8%));

            .search {
                padding: $space;

                .title {
                    font-size: 1.2rem;
                    color:$default;
                    padding-bottom: $space-xxs;
                    @include border(.05rem, dotted, $border, bottom);
                    margin-bottom: $space;
                }

                .btn {
                    &:hover {
                        background-color: #000;
                    }
                }
            }

            .search-images {
                @include responsive(sm){display: none;}
                @include responsive(xs){display: none;}
                align-items: center;

                img {
                    width: 68%;
                    display: inline-flex;
                }
            }
        }
    }

    //content
    .content {
        .title {
            text-align: center;
            margin-bottom: $space-lg;
            h2 {
                font-size: $size-md;

                strong {
                    color: $green;
                }
            }
        }

        .description {
            text-align: center;
            p {
                font-size: .9rem;
                color:$default;
                line-height: 1.2;
            }
        }

    }

    // info content
    .info-content {
        margin-top: -($space-xl * 3);
        padding: ($space-xl * 4) 0 ($space-xl * 4) 0;
        background:url(../images/content_bg.jpg) no-repeat;
        background-size: cover;

        .cont {
            @include border(.05rem, dotted, $default, bottom);
        }
        .center-images {
            display: flex;
            justify-content: center;
            align-items: center;
            @include responsive(sm) {display: none;}
            @include responsive(xs) {display: none;}
            img {
                width: 80%;
            }
        }

        .item {
            margin-bottom: $space-sm;

            &.right {
                .row {
                    flex-direction: row-reverse;
                }

                h2 {
                    text-align: right;

                    &:after {
                        right: auto;
                        left: -.4rem;
                    }
                }

                p {
                    text-align: right;
                }
            }

            &:hover {
                h2 {
                    border-color:$green;
                    color:$green;
                    font-weight: 600;
                    &:after {
                        background-color: $green;
                    }
                }
            }
            .icon {
                height: 5rem;
                color:$green;
                font-size: 4rem;
                justify-content: center;
                align-items: center;
                display: inline-flex;
                @include easing();
                @include borderRadius(.2rem);
            }

            h2 {
                font-size: 1.3rem;
                margin:0;
                font-weight: normal;
                padding-bottom: $space-xxs;
                @include border(.05rem, dotted, $default, bottom);
                position: relative;
                color:$default;

                &:after {
                    content:"";
                    width: .7rem;
                    height: .7rem;
                    background-color: $default;
                    @include borderRadius(50%);
                    position: absolute;
                    right: -.4rem;
                    top: 1.88rem;
                    @include easing();
                }
            }
            p,span,div {
                font-size: .9rem;
                color:$default;
                margin-top: $space-xs;
                line-height: 1.3;
                font-family: Geogrotesque Rg !important;
            }
        }
    }

    .news-content {
        margin-top: -($space-xl * 2);
        position: relative;
        z-index: 9;

        .news-slider {

            &:hover {
                .owl-controls {
                    .owl-nav {
                        div {
                            opacity: 1;
                        }
                    }
                }
            }

            a {
                position: relative;
                height: 29rem;
                display: block;
                background-color: #1e1e1e;
                @include responsive(xs){height: auto;}


                &:hover {
                    .title {
                        background-color: rgba(0, 97, 64, 0.80);
                    }

                    .images {
                        opacity: 1;
                    }

                    .btn {
                        opacity: 1;
                    }
                }

                .images {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top:0;
                    opacity: 0.2;
                    @include easing();

                    @include responsive(xs){position: relative;}

                    img {
                        min-height: 100%;
                    }
                }

                .title {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    top:$space;
                    padding: $space-xxs;
                    color:$white;
                    text-align: left;
                    line-height: 1.2;
                    width: 80%;
                    font-size: 1.2rem;
                    @include easing();
                }

                .btn {
                    opacity: 0;
                    position: absolute;
                    bottom: $space;
                    right: $space;
                    font-size: $size-xs;
                    padding: 0;
                    background-color: rgba(0, 97, 64, 0.80);
                    @include easing();
                    i {
                        padding: $space-xs;
                        @include border(.05rem, solid, rgba(255, 255, 255, 0.35), right);
                    }
                    span {
                        padding: $space-xs;
                    }
                }
            }

            .owl-controls {
                .owl-nav {
                    width: 100%;
                    div {
                        position: absolute;
                        top: 45%;
                        z-index: 999;
                        left:1rem;
                        color:$white;
                        padding: $space-xs $space;
                        background-color: rgba(188, 188, 188, 0.49);
                        opacity: 0;
                        @include easing();

                        &.owl-next {
                            right: 1rem;
                            left: auto;
                        }

                        &:hover {
                            background-color: rgba(0, 97, 64, 0.80);
                        }
                    }
                }
            }
        }
    }

    .tractors {
        padding: $space-xl 0;

        &:hover {
            .owl-controls {
                .owl-nav {
                    div {
                        opacity: 1;
                    }
                }
            }
        }
        .title {
            width: 100%;
            text-align: center;
        }

        .description {
            text-align: center;
            p {
                font-size: .9rem;
                color:$default;
                line-height: 1.2;
            }
        }

        .home-products {
            margin-top: $space-md;

            .item {
                text-align: center;

                .text {
                    margin-top: $space-xs;
                    h4 {
                        font-size: 1.1rem;
                        color:$dark;
                        margin-bottom: 0;
                    }
                    span {
                        margin-top: $size-xs;
                        display: block;
                        font-size: .8rem;
                        color:$grey-regular;
                    }
                }

                .btn {
                    margin-top: $space;
                }
            }
        }


        .owl-controls {
            .owl-nav {
                width: 100%;
                div {
                    position: absolute;
                    top: 45%;
                    z-index: 999;
                    left:-4rem;
                    color:$white;
                    padding: $space-xs $space;
                    background-color: rgba(0, 0, 0, 0.8);
                    width: 2.5rem;
                    height: 2.5rem;
                    @include borderRadius(50%);
                    @include easing();
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    opacity: 0;

                    &.owl-next {
                        right: -4rem;
                        left: auto;
                    }

                    i {
                        width: 1rem;
                        height: 1rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                }
            }
        }

    }

    .we-use {
        background: url(../images/deg_bg.jpg) no-repeat;
        background-size: cover;
        padding: $space-lg;
        .title {
            h2 {
                color:$white;
            }
        }
        .description {
            p {
                color:$white;
            }
        }

        .icons {
            margin-top: $space-lg;
            .item {
                text-align: center;
                color: $white;
                @include border(.03rem, solid, #c2cac7, left);
                @include responsive(sm) {border: 0;}
                @include responsive(xs) {border: 0;}

                &:first-child {
                    border:0;
                }
                .icon {
                    color: #c2cac7;
                    font-size: 3.5rem;
                }
                h2 {
                    display: block;
                    color: $white;
                    font-size: $size-sm;
                    font-weight: normal;
                    margin-top: 1.5rem;
                }
            }
        }
    }

    .social-content {
        padding: $space-lg 0;
        background: url(../images/social_bg.jpg) no-repeat;
        background-size: cover;

        .line {
            background: #000 url(../images/social_line_bg.png) no-repeat;
            background-position: center;
            color:$white;

            @include responsive(xs){display: none;}

            span {
                padding: $space $space-sm;
                @include border(.05rem, solid, $white, right);
            }
            p {
                margin-bottom: 0;
                padding: $space;
            }
        }
        .social {
            @include responsive(xs){text-align: center}
            a {
                width: 3rem;
                height: 3rem;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 1.2rem;
                color:$white;
                margin: 0 .2rem;

                &.facebook {background-color: #3b5999;}
                &.twitter {background-color: #00bbf5;}
                &.linkedin {background-color: #1385c4;}
            }
        }
    }

    // detail
    .detail-content {
        overflow: hidden;

        &.background {
            background: url(../images/content_bg.jpg) no-repeat center;
            background-size: cover;
        }


        .title {
            position: relative;
            width: $full;
            overflow: hidden;

            &.static {
                position: static;
                overflow: inherit;
                h4 {
                    position: absolute;
                }
            }
            h4 {
                font-size: $size-sm;
                float: left;
                background: rgba(1, 53, 36, 0.75);
                background-size: cover;
                color: #ffffff;
                padding: 1.5rem;
                border-radius: 0rem .5rem .5rem 0rem;
                margin-top: 8rem;

                @include responsive(sm) {margin-top: 1.5rem;}
                @include responsive(xs) {margin-top: 1.5rem;}
            }
            p {
                margin: 0;
                margin-top: $space-xs;
                font-size: .9rem;
            }

            .banner {
                height: 45rem;
                overflow: hidden;
                @include responsive(sm) {height: auto;}
                @include responsive(xs) {height: auto;}

                img {
                    @include responsive(sm){width: $full;height: auto;}
                    @include responsive(xs){width: $full;height: auto;}
                }
            }
        }

        .main-content {
            padding-bottom: $space-xl;
            .static-images {
                max-height: 35rem;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                }
            }
            .text {
                margin-top: $space;

                span,
                p,
                strong{
                    font-family: $fontFamily !important;
                }
                h5 {
                    font-size: 1.2rem;
                }
                span {
                    margin-bottom: $space;
                    display: block;
                }
                p {
                    font-size: $size;
                    color:$default;
                    line-height: 1.3;

                    &.grid-text {
                        -webkit-column-count: 3;
                        -moz-column-count: 3;
                        column-count: 3;
                        -webkit-column-gap: 30px;
                        -moz-column-gap: 30px;
                        column-gap: 30px;

                        @include responsive(sm){column-count:1 !important;}
                        @include responsive(xs){column-count:1 !important;}
                    }
                }
            }

            .news {
                .news-text {
                    position: relative;
                    .static-images {
                        width: $full;
                        max-height: 30rem;
                        overflow: hidden;

                        img {
                            min-width: $full;
                            height: auto;
                        }
                    }
                    p {
                        margin-top: $space !important;
                        font-size: $size;
                        line-height: $size-sm;
                        color:$default;
                    }

                    .date {
                        padding: .5rem 1rem;
                        background: #3d6457;
                        position: absolute;
                        right: 0.5rem;
                        top: -32px;
                        color: #fff;
                        border-radius: 5px 5px 0px 0px;

                        @include responsive(xs) {top:0;border-radius: 0px 0px 5px 5px;}
                    }

                    .sub-title {
                        font-size: 1.3rem;
                        margin-top: 1.6rem;
                        margin-bottom: 0;
                    }
                }

                .item {
                    background-color: rgba(255, 255, 255, 0.69);
                    padding: .7rem .25rem;
                    @include borderRadius(.2rem);
                    @include easing();
                    margin-bottom: $space-xs;
                    @include responsive(sm) {width: 48%;float: left;}

                    &:nth-child(2n+2){
                        @include responsive(sm){margin-left: 2%;}
                    }

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.90);
                    }

                    .images {
                        width: $full;
                        height: 7rem;
                        overflow: hidden;
                        @include borderRadius(.2rem);
                        @include responsive(sm) {height: 32rem;margin-bottom: $space}
                        @include responsive(xs) {height: auto;margin-bottom: $space}
                        img {
                            width: $full;
                        }
                    }
                    .text {
                        margin-top: 0;
                        @include responsive(sm){height: 4rem;}
                        a {
                            h3 {
                                color:$dark;
                                font-size: $size-sm;
                                margin-bottom: .4rem;
                            }
                        }
                        p {
                            color:$default;
                        }
                    }
                }
            }
        }
    }

    .filter-button {
        display: none;
        @include responsive(sm) {display: block;}
        @include responsive(xs) {display: block;}
    }

    // filters
    .filters {
        @include responsive(sm) {display: none;}
        @include responsive(xs) {display: none;}
        .box {
            padding: $space;
            @include border(.05rem, solid, $border, bottom);

            .title-bx {
                h5 {
                    font-size: 1.2rem;
                    color:$dark;
                    display: inline-flex;
                    margin-bottom: 0;
                    font-weight: normal;
                }
                .close {
                    float: right;
                    margin-right: $space-xxs;
                    &:after {
                        content: "\f106";
                        font: normal normal normal 1rem FontAwesome;
                        color:$grey-regular;
                    }
                }
            }

            .area {
                margin-top: $space;
                margin-top: 1rem;
                max-height: 10rem;
                overflow-y: auto;

                .search {
                    position: relative;

                    &:before {
                        content: "\f002";
                        font: normal normal normal 1rem FontAwesome;
                        color:$grey-regular;
                        position: absolute;
                        left: 0.6rem;
                        top: 0.7rem;
                    }

                    .frm {
                        padding-left: 2.1rem;
                        border-color:transparent;
                        border-bottom-color:$grey-regular;
                    }
                }

                .lists.prop {
                    margin-top: $space;

                    li {
                        .form-item {
                            margin-bottom: $space-xs;
                            label {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                &.scroll {
                }

                .more {
                    font-size: $size-xs;
                    color:$default;
                }
            }
        }
        .title-bx {
            h4 {
                font-size: 1.2rem;
                font-weight: 500;
                color:$dark;
                margin-bottom: 0;
                line-height: 1.1;
            }
            .count {
                text-align: right;
                font-size: $size;
                color:$default;

                em {
                    display: block;
                    font-size: .9rem;
                    margin-top: .2rem;
                }
            }
        }

        .category {
            em {
                font-size: $size-xs;
                color:$default;
                font-weight: bold;
                display: block;
                margin-bottom: $space-xxs;
            }
        }

        .buttons {
            padding: $space;
            .btn {
                &:first-child {
                    margin-bottom: $space-xs;
                }
            }
        }
    }

    // product lists
    .product-lists {
        .product {
            background-color: $white;
            @include shadow(0, .05rem, .5rem,  $grey-thin);
            @include border(.05rem, solid, #e6e6e6);
            @include easing();
            margin-bottom: $space;
            position: relative;

            &:hover {
                .hover {
                    opacity: 1;
                }
            }

            .hover {
                position: absolute;
                width: $full;
                height: $full;
                background-color: rgba(100, 99, 99, 0.44);
                z-index: 999;
                opacity: 0;
                top: 0;
                bottom:0;
                @include easing();
            }

            .images {
                width: $full;
                height: 15rem;
                position: relative;
                overflow: hidden;
                @include responsive(sm) {height: auto;}
                @include responsive(xs) {height: auto;}

                img {
                    height: $full;
                    min-width: $full;
                    @include easing();
                    @include responsive(sm) {height: auto;width: $full;}
                    @include responsive(xs) {height: auto;width: $full;}
                }

                .category-title {
                    padding: $space-xxs $space;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: $green;
                    color: $white;
                }
            }

            .text {
                padding: $space;
                margin-top: $space-xs;
                h3 {
                    font-size: 1.2rem;
                    color:$dark;
                }
                .properties {
                    span {
                        display: block;
                        margin-bottom: $space-xxs;
                    }
                }
            }
        }
    }

    // product detail
    .product-detail {
        .btn.find-dealership {
            position: absolute;
            top: -4.6rem;
            right: 0.6rem;
            @include borderRadius();

            &.basic {
                position: relative !important;
                top:auto;
                right: auto;
                margin: $space auto;
                display: none;
            }
        }

        .product-main-image {
            height: 30.5rem;
            text-align: center;
            overflow: hidden;
            @include responsive(sm) {height: auto;}
            @include responsive(xs) {height: auto;}

            img {
                height: $full;
                display: inline-block;
                @include responsive(sm) {height: auto;width: $full;}
                @include responsive(xs) {height: auto;width: $full;}
            }
        }

        .product-properties {
            .item {
                width: $full;
                @include borderRadius();
                background-color: $white;
                @include shadow(0, .05rem, .5rem,  $grey-thin);
                padding: $space;
                margin-bottom: .3rem;
                @include border(.05rem, solid, $border);

                strong {
                    color: $dark;
                    font-size: $size;
                    font-weight: bold;
                }
                span {
                    font-size: $size;
                    color:$dark;
                }
            }
        }

        .cont-item {
            .title-cnt {
                @include border(.2rem, solid, $green, left);
                padding: $space-xxs;

                h3 {
                    font-size: $size-sm;
                    color:$green;
                    margin-bottom: 0;
                }
            }
            .gallery-lists {
                margin-top: $space-sm;
                .item {
                    height: 13rem;
                    position: relative;
                    margin-bottom: $space;
                    overflow: hidden;
                    @include responsive(xs) {height: auto;}

                    &:hover {
                        .hover {
                            opacity: 1;
                        }
                    }

                    img {
                        min-width: $full;
                        height: $full;
                        @include responsive(xs) {width: $full;height: auto;}
                    }
                    .hover {
                        @include easing();
                        position: absolute;
                        width: $full;
                        height: $full;
                        background-color: rgba(0, 0, 0, .5);
                        align-items: center;
                        justify-content: center;
                        left:0;
                        top:0;
                        display: flex;
                        opacity: 0;

                        i {
                            width: $size-sm;
                            height: $size-sm;
                            color:$white;
                            font-size: $size-sm;
                        }
                    }
                }
            }

            .brochures-list {
                margin-top: $space;
                .item {
                    background-color: $white;
                    padding: $space-md $space;
                    text-align: center;
                    i {
                        font-size: 4rem;
                        display: block;
                        color:$yellow;
                        display: inline-block;
                    }
                    span {
                        margin-top: $space;
                        font-size: $size;
                        color:$dark;
                        display: block;
                    }
                }
            }
        }

        .product-info-text {
            width: $full;
            text-align: center;
            position: relative;
            margin-top: $space;

            @include responsive(sm) {position: relative;margin-top: $space;width: $full;}
            @include responsive(xs) {position: relative;margin-top: $space;width: $full;}

            &:after {
                content:"";
                //background: url(../images/product_info_icon.png) no-repeat;
                width: 10rem;
                height: 10rem;
                position: absolute;
                left: -1.3rem;
                top: -1.1rem;
            }

            .info-text {
                background-color: $grey-thin;
                @include borderRadius(.4rem);
                padding: $space $space-sm;
                h4 {
                    font-size: 1.1rem;
                    color:$dark;
                }
                p {
                    font-size: .9rem;
                    color: $default;
                }
            }

        }
    }

    // pdf button
    .pdf-button {
        position: relative;

        .images {
            position: absolute;
            left: -2.3rem;
            top: 9.9px;
            z-index: 99;

            img {
                width: 3.5rem;
            }
        }

        .btn {
            display: inline-flex;
            margin-top: $space;
            padding: $space $space-sm;
            color:$white;
            background-color: $green;
            @include borderRadius(.4rem);
            @include shadow(0, .2rem, .4rem, $grey-regular);

            &:hover {
                background-color: #000000;
                color:$white;
            }
        }
    }

    // after sales
    .tab-accordion {
        .step {
            margin-bottom: $space;

            &.active {
                border-color:$green;
            }

            .title {
                padding: $space;
                font-size: 1.2rem;
                text-align: center;
                background-color: $grey-thin;
                cursor: pointer;
                color:$green;
                @include easing();
                @include border(.1rem, solid, transparent);

                &:hover {
                    background-color: darken($grey-thin, 1%);
                }

                span {
                    display: inline-block;
                }
                i {
                    float: right;
                }
            }

            .cont-text {
                display: none;
                margin-top: .2rem;
                background-color: $grey-thin;
                padding: $space;
                color:$green;

                a {
                    color:$green;
                    text-decoration: underline;
                    font-size: 1.2rem;
                    margin-bottom: $space;
                }
            }
        }
    }

    .contact {
        .address {
            &.block-address {
                label {
                    display: block;

                    strong {
                        width: $full;
                        display: block;
                    }
                    span {
                        display: block;
                        width: $full;
                        margin-top: .3rem;
                    }
                }
            }
            label {
                display: block;
                width: $full;
                padding: $space;

                strong {
                    display: inline-block;
                    width: 20%;
                }
                span {
                    display: inline-block;
                    width: 79%;
                }
            }
        }

        .team {
            .title {
                h3 {
                    font-size: 1.2rem;
                }
                p {


                }
            }

            .team-person {
                .item {
                    h3 {
                        font-size: 1.2rem;
                    }
                }

                label {
                    margin-top: $space;
                    display: block;

                    strong {
                        display: inline-block;
                        width: 20%;
                    }
                    span {
                        display: inline-block;
                        width: 79%;
                    }
                }
            }
        }

        .form {
            .title {
                margin-bottom: $space-xl;
                h3 {
                    font-size: 1.2rem;
                }
                p {
                    color:$green;

                }
            }

            .btn {
                &:hover {
                    background-color: #000;
                }
            }
        }
    }

    .lists-link {
        color:$green;
        font-size: 1.1rem;
        padding: $space;
        background-color: $grey-thin;
        @include easing();
        margin-top: $space;
        width: $full;
        display: block;
        font-weight: bold;

        &:hover {
            background-color: darken($grey-thin, 5%);
        }
    }
}

.popup {
    .legal-text {
        height: 30rem;
        overflow-y:auto;
    }
}


footer {
    background-color: #11201b;
    padding: $space-md 0;
    padding-bottom: 0;

    &.static {
        .top-footer {

        }
    }

    .top-footer {
        text-align: center;
        padding-bottom: $space;

        .logo {
            width: 20%;
            margin: auto;
            @include responsive(sm) {width: 40%;}
            @include responsive(xs) {width: 60%;}

            a {
                display: block;

                img {
                    width: 100%;
                }
            }
        }
    }

    .main-footer {
        margin-top: $space-xl;
        @include border(.05rem, solid, #a6a6a6, top);
        position: relative;
        padding-top: $space-md;

        &:before {
            content: "";
            width: 2.6rem;
            height: 1rem;
            background: #11201b url(../images/footer_line_arrow.jpg) no-repeat center;
            position: absolute;
            left: 0;
            right: 0;
            top: -3px;
            margin: auto;
        }
        h3 {
            font-size: $size-sm;
            color:$white;
            font-weight: normal;
        }
        ul {
            @include responsive(sm){margin-bottom: $space;}
            @include responsive(xs){margin-bottom: $space;}
            li {
                a {
                    color: $white;
                    margin-bottom: .2rem;
                    display: block;
                    @include easing();
                    &:hover {
                        color: $default;
                    }
                    &:before {
                        content: "\f105";
                        font: normal normal normal $size/1 FontAwesome;
                        margin-right: $space-xxs;
                    }
                }
            }
        }

        .copyright {
            margin-top: $space;
            p {
                color:$white;
                font-size: .8rem;
            }
        }
    }

    .popups {
        text-align: center;
        width: $full;
        margin-top:$space-md;

        .item {
            display: inline-block;
            font-size: $size;
            color:$white;
            a {
                color:$white;
                display: block;

                &:hover {
                    color:darken($white, 10%);
                }
            }
        }
    }
}

