/*
popup style
 */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: $full;
  height: $full;
  overflow-y: auto;
  overflow-x: auto;
  background: rgba(0, 0, 0, 0.70);
  z-index: 99999999;
  display: none;

  &.show {
    display: block;
  }

  // overlay
  .overlay {
    position: absolute;
    width: $full;
    height: $full;
    background: none !important;
  }

  // popup main
  .popup-main {
    min-width: 400px;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 2;
    opacity: 0;
    max-width: 93%;
    z-index: 99;

    &.show {
      opacity: 1;
    }

    .popup-head {
      background-color: #000000;

      .popup-head-title {
        font-size: $size-sm;
        color: $white;
        width: calc(100% - 3rem);
        padding: ($space - .3rem) $space;

        i {
          margin-right: $space-xxs;
        }
      }

      .popup-close {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 9;
        color: $white;
        cursor: pointer;
        font-size: 25px;
        width: 3rem;
        height: 2.65rem;
        background-color: lighten(#000000, 10%);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: $size;
        @include easing();

        &:hover {
          background-color: darken(#000000, 5%);
        }
      }
    }

    .popup-content {
      padding: $space;
    }

    .popup-footer {
      padding: $space;
    }
  }
}

.popupContent {
  display: none;
}
